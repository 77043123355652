@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
[data-sparta-container] {
  .sparta-show-hide-list {
    background-color: transparent;

    .controls {
      color: $brand-royal-blue;

      &.hide {
        display: none;
      }
    }
  }

  section[data-sparta-show-hide-id] {
    .sparta-show-hider {
      background-color: transparent;
      display: inline-block;
      margin: -$spacing-2;
      padding: $spacing-8 $spacing-24 $spacing-8 0;
      position: relative;
      z-index: 1;

      &:focus-within,
      &:hover {
        color: $color-royal-one;
        text-decoration: underline;
      }

      .chevron {
        background-position: center 6px;
        border: none;
        bottom: 0;
        color: $color-gray;
        height: 24px;
        margin: auto $spacing-4;
        outline: none;
        padding: 0;
        position: absolute;
        top: 0;
        transition: $spa-standard-transition;
        width: 24px;

        &.active {
          transform: rotate(-180deg);

          &.icon-chevron-down {
            background-position: center -6px;
          }
        }

        @media screen and (-ms-high-contrast: none) {

          &:focus {
            outline: 1px dotted $color-black-alt;
          }
        }
      }

      .spa-show-hide-control {
        color: $color-black;
        line-height: 44px;
        text-align: left;
        display: flex;

        .show-hide-button-text {
          font-family: $roboto-family;
          font-size: 16px;
          line-height: 24px;
        }

        &:hover{
          color: $color-royal-one;
          text-decoration: underline;

          .icon-chevron-down {
            &::after {
              opacity: 1;
            }
          }
          .icon-chevron-up {
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }

    button {
      background-color: transparent;
      margin: 0;
      padding: 0;

      &:hover,
      &:focus {
        color: $color-royal-one;
        cursor: pointer;
        text-decoration: underline;
      }

      .show-hide-button-arrow.show-hide-button-arrow {
        position: relative;
      }
    }

    .sparta-show-hidee {
      background: transparent;
      max-height: 0;
      overflow: hidden;
      transition: max-height $spa-standard-transition-time $spa-standard-transition-easing;

      &.active {
        max-height: 100%;
      }
    }

    .sparta-show-hide--spacer {
      display: block !important; 
      position: absolute;
      z-index: -100000;
    }
  }
}
